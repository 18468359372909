export default function InactiveView() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h2 className="text-2xl font-bold mb-4">
        Ditt konto är inaktivt. Kontakta oss för att aktivera ditt konto.
      </h2>
      <p className="text-lg">
        <a href="mailto:info@graftus.ai" className="text-blue-500 underline">
          info@graftus.ai
        </a>
      </p>
    </div>
  );
}
